<!--
 * @Author: sopen
 * @Date: 2021-06-30 10:04:37
 * @LastEditTime: 2021-07-31 10:15:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/home/usinfo.vue
-->
<template>
  <v-app>
    <inc-header
      id="menu"
      :project="{ name: '监护人信息' }"
      :mobile="mobile"
      :menu="menu"
      :help="false"
    ></inc-header>
    <v-container app fluid>
      <v-row>
        <v-col cols="12" md="10" offset-sm="1">
          <v-alert outlined type="info" prominent border="left">
            用户信息的姓名、身份证号码必须是真实信息，否则无法完成单位身份的关联。将影响帐号的正常使用，而且，用户在录入姓名、身份证号码后，将无法修改。请务必谨慎检查内容是否正确。
          </v-alert>
        </v-col>
        <v-col cols="12" md="10" offset-sm="1">
          <v-row>
            <v-col cols="12" md="6">
              <v-card>
                <v-system-bar color="indigo lighten-2" dark> </v-system-bar>

                <v-toolbar elevation="1" color="">
                  <v-icon class="mr-4 text-h4" color="orange"
                    >mdi-card-account-details-outline</v-icon
                  >

                  <v-toolbar-title>用户信息</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-4 px-6">
                  <v-text-field v-model="user.us" label="UID" disabled outlined>
                  </v-text-field>
                  <v-text-field v-model="user.userid" outlined label="USERID" disabled>
                  </v-text-field>
                  <v-text-field
                    v-model="user.name"
                    label="姓名"
                    :readonly="disabled"
                    outlined
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="user.idcard"
                    label="身份证"
                    :readonly="disabled"
                    outlined
                  >
                  </v-text-field>
                  <v-radio-group
                    label="性别"
                    v-model="user.sex"
                    :readonly="disabled"
                    outlined
                  >
                    <v-radio label="男" :value="1"></v-radio>
                    <v-radio label="女" :value="2"></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-alert type="error" dense class="mx-2" v-if="disabled">
                  内容已被锁定
                </v-alert>
                <v-card-text align="center">
                  <v-btn color="success" :disabled="disabled" @click="dialog = true">
                    <v-icon>mdi-check</v-icon>
                    保存信息
                  </v-btn> </v-card-text
                >`
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                class="mb-6"
                v-if="teachers && Object.keys(teachers).length > 0 ? true : false"
              >
                <v-system-bar color="indigo lighten-2" dark> </v-system-bar>
                <v-toolbar color="deep-purple lighten-4" elevation="1">
                  <v-icon class="mr-4">mdi-account-network-outline</v-icon>
                  <v-toolbar-title>相关联的工作身份</v-toolbar-title>
                </v-toolbar>

                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(ts, x) in teachers" :key="x">
                        <td>
                          {{ ts["name"] + " / " + ts["schoolname"] }}
                          <br />
                          <span
                            v-if="
                              ts['usid'] < 1 || (ts['usid'] > 0 && user.us == ts['usid'])
                                ? false
                                : true
                            "
                            class="font-italic red--text"
                            >{{ user.us }}其他用户已关联</span
                          >
                        </td>
                        <td style="max-width: 140px; width: 130px" align="right">
                          <v-btn
                            small
                            :disabled="
                              !(
                                ts['usid'] < 1 ||
                                (ts['usid'] > 0 && user.us == ts['usid'])
                              )
                            "
                            :color="ts['slink'] === true ? 'success' : ''"
                            @click="change(['teacher', ts])"
                          >
                            {{ ts["slink"] === true ? "已关联" : "未关联" }}</v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <v-card
                class="mb-6"
                v-if="student && Object.keys(student).length > 0 ? true : false"
              >
                <v-system-bar color="indigo lighten-2" dark> </v-system-bar>
                <v-toolbar color="deep-purple lighten-4" elevation="1">
                  <v-icon class="mr-4">mdi-account-network-outline</v-icon>
                  <v-toolbar-title>相关联的学生身份</v-toolbar-title>
                </v-toolbar>

                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(ts, x) in student" :key="x">
                        <td>
                          {{ ts["name"] + " / " + ts["schoolname"] }}
                          <br />
                          <span
                            v-if="
                              ts['usid'] < 1 || (ts['usid'] > 0 && user.us == ts['usid'])
                                ? false
                                : true
                            "
                            class="font-italic red--text"
                            >{{ user.us }}其他用户已关联</span
                          >
                        </td>
                        <td style="max-width: 140px; width: 130px" align="right">
                          <v-btn
                            small
                            :color="ts['slink'] === true ? 'success' : ''"
                            @click="change(['student', ts])"
                          >
                            {{ ts["slink"] === true ? "已关联" : "未关联" }}</v-btn
                          >
                          <!--
                      <v-switch @change="change($event,x)" v-model="ts['slink']" :label="ts['slink']===true?'已关联':'未关联'">
                      </v-switch>
                      -->
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" persistent max-width="390">
        <v-card>
          <v-card-title class="text-h5" :class="errColor">
            <span>{{ errTitle }}</span>
          </v-card-title>
          <v-card-text :class="errColor">{{ errMsg }}</v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="dialog = false"> 取消 </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" elevation="0" @click="saveInfo">
              <v-icon>mdi-check</v-icon>
              确认修改
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="authDialog" persistent max-width="390">
        <v-card>
          <v-card-title class="text-h5"> 改变用户身份提醒 </v-card-title>
          <v-card-text
            >你的用户身份发生变化，这将影响你的相关功能，除非你确认需要进行更改。</v-card-text
          >
          <v-card-actions>
            <v-btn @click="authDialog = false"> 取消 </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" dark @click="authChange">
              <v-icon class="mr-2">mdi-check</v-icon>
              确认变更身份
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar top v-model="snackbar">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
//import { Button } from 'element-ui'
//import 'element-ui/lib/theme-chalk/index.css';
import incHeader from "../inc-header.vue";
export default {
  components: { incHeader },
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      menu: [],
      userinfo: {
        us: "用户ID",
        userid: "登录ID",
        name: "姓名",
        sex: "性别",
        idcard: "身份证号码",
      },
      radios: 1,
      user: {
        us: null,
        userid: null,
        name: null,
        idcard: null,
        sex: null,
      },
      teachers: {},
      student: {},
      disabled: false,
      dialog: false,
      authDialog: false,
      authItem: "",
      errTitle: "系统提示",
      errMsg: "你确认要提交用户信息吗？用提交后无法修改。",
      errColor: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  mounted() {
    this.usinfo();
  },
  methods: {
    usinfo() {
      //let usCookie = localStorage.getItem("usCookie")
      let data = {
        module: "usinfo",
        title: "info",
        data: {},
      };

      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        console.log(res);
        //let user=JSON.parse(res.data)
        that.user = res.data.usinfo;
        if (that.user.idcard != "" && that.user.name != "") {
          that.disabled = true;
        }
        if (
          res.data.usinfo.teacher &&
          Object.keys(res.data.usinfo.teacher.data).length > 0
        ) {
          that.teachers = res.data.usinfo.teacher.data;
          for (let x in that.teachers) {
            that.teachers[x]["slink"] = that.teachers[x]["link"] == 1 ? true : false;
          }
          that.offsetSm = 0;
          ////console.log(that.teachers)
          //that.offsetSm = Object.keys(that.teachers).length
        }
        if (
          res.data.usinfo.student &&
          Object.keys(res.data.usinfo.student.data).length > 0
        ) {
          that.student = res.data.usinfo.student.data;
          for (let x in that.student) {
            that.student[x]["slink"] = that.student[x]["link"] == 1 ? true : false;
          }
          ////console.log(that.student)
          that.offsetSm = 0;
        }
        //that.offsetSm = Object.keys(that.student).length
      });
    },
    userItem(x, ts) {
      switch (x) {
        case "sex":
          return ts == 1 ? "男" : "女";
        default:
          return ts;
      }
    },
    saveInfo() {
      let data = {
        module: "usinfo",
        title: "sinfo",
        data: {
          name: this.user.name,
          sex: this.user.sex,
          idcard: this.user.idcard,
          area: "cn",
        },
      };
      ////console.log(data)
      let that = this;

      this.$sopen.requestUscenterApi(data).then(function (res) {
        ////console.log(res)
        if (res.data.usinfo.errcode > 0) {
          that.errTitle = "错误";
          that.errColor = "red--text";
          that.errMsg = res.data.usinfo.errmsg;
        } else {
          that.dialog = false;
          that.snackbar = true;
          that.snackbarText = "保存成功！";
          that.disabled = true;
          that.usinfo();
        }
      });
    },
    change(ts) {
      this.authItem = ts;
      if (ts[1]["link"] < 1) {
        this.authChange();
      } else {
        this.authDialog = true;
      }
    },
    authChange() {
      let ts = this.authItem;
      //console.log(ts);
      let data = {
        module: "usinfo",
        title: "linkUser",
        data: {
          type: ts[0],
          id: ts[1]["id"],
        },
      };
      //this.$forceUpdate();

      let that = this;
      this.$sopen.requestUscenterApi(data).then(function () {
        ////console.log(res);
        //that.usinfo();
        that.authDialog = false;
        window.location.reload();
      });
    },
  },
  created() {
    //this.$parent.$parent.$parent.selectedItem = 0;
  },
  watch: {
    dialog(oldName, newName) {
      if (newName == false) {
        this.errColor = "";
        this.errTitle = "系统提示";
        this.errMsg = "你确认要提交用户信息吗？用提交后无法修改。";
      }
    },
  },
};
</script>
